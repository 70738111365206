import React from 'react'
import ContactPage from '../components/ContactPage'

export default function Contact() {
  return (
    <div>
      <ContactPage/>
    </div>
  )
}
